import React from "react";

export const Service = (props) => {
  return (

    <section id="services" className="services-area services-eight">
      <div className="section-title-five">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="content">
                <h6>{props.data ? props.data.title : "Loading"}</h6>
                <h2 className="fw-bold">{props.data ? props.data.subTitle : "Loading"}</h2>
                {/*<p>
                  There are many variations of passages of Lorem Ipsum available,
                  but the majority have suffered alteration in some form.
                </p>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          {props.data
            ? props.data.datails.map((d, i) => (
              <div key={`${d.name}-${i}`} className="col-lg-6 col-md-6">
                {" "}
                <div className="single-services">
                  <div className="service-icon">
                    <i className={d.icon}></i>
                  </div>
                  <div className="service-desc">
                    <h4>{d.name}</h4>
                    <p>{d.text}</p>
                  </div>
                </div>
              </div>
            ))
            : "loading"}
        </div>
      </div>
    </section>

  );
};