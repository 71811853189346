import React from "react";

export const AboutUs = (props) => {
  return (
    <section id="about-us" className="pricing-area pricing-fourteen">
    <div className="section-title-five">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="content">
              <h6>{props.data ? props.data.title: "Loading"}</h6>
              <p>
                {props.data ? props.data.slogan: "Loading"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="row">
        <div className="col-lg-6 col-md-6 col-12">
          <div className="pricing-style-fourteen middle">
            <div className="table-head">
              <h6 className="title">{props.data ? props.data.tittleMision: "Loading"}</h6>
                <p>{props.data ? props.data.mision: "Loading"}</p>
                
            </div>

            {/*<div className="light-rounded-buttons">
              <a href="#" className="btn primary-btn-outline">
                Start free trial
              </a>
            </div>

            <div className="table-content">
              <ul className="table-list">
                <li> <i className="lni lni-checkmark-circle"></i> Cras justo odio.</li>
                <li> <i className="lni lni-checkmark-circle"></i> Dapibus ac facilisis in.</li>
                <li> <i className="lni lni-checkmark-circle deactive"></i> Morbi leo risus.</li>
                <li> <i className="lni lni-checkmark-circle deactive"></i> Excepteur sint occaecat velit.</li>
              </ul>
            </div>
            */}
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-12">
          <div className="pricing-style-fourteen middle">
            <div className="table-head">
              <h6 className="title">{props.data ? props.data.tittleVision: "Loading"}</h6>
                <p>{props.data ? props.data.vision: "Loading"}</p><br></br>
            </div>
          </div>
        </div>
      
      </div>
    </div>
  </section>



  );
};