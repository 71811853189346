import React from "react";

export const Navigation = (props) => {
  return (
    <section className="navbar-area navbar-nine">
    <div className="container">
    <div className="row">
        <div className="col-lg-12">
        <nav className="navbar navbar-expand-lg">
            <a className="navbar-brand" href="index.html">
            <img  style ={{width: 170 + 'px', height: 50 + 'px'}} src="assets/images/GHUSOF-NEGATIVO-SIN-FONDO.png" alt="Logo" />
            </a>

            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNine"
            aria-controls="navbarNine" aria-expanded="false" aria-label="Toggle navigation">
            <span className="toggler-icon"></span>
            <span className="toggler-icon"></span>
            <span className="toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse sub-menu-bar" id="navbarNine">
            <ul className="navbar-nav me-auto">
                <li className="nav-item">
                <a className="page-scroll active" href="#hero-area">{props.data ? props.data.home : "Loading"}</a>
                </li>
                <li className="nav-item">
                <a className="page-scroll" href="#about-us">{props.data ? props.data.aboutus : "Loading"}</a>
                </li>
                <li className="nav-item">
                <a className="page-scroll" href="#services">{props.data ? props.data.services : "Loading"}</a>
                </li>
                <li className="nav-item">
                <a className="page-scroll" href="#contact">{props.data ? props.data.contact : "Loading"}</a>
                </li>
            </ul>
            </div>
        </nav>

        </div>
    </div>

    </div>

    </section>


  );
};