import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { AboutUs } from "./components/about-us";
import { Service } from "./components/service";
import { Contact } from "./components/contact";
import { Footer} from "./components/footer";
import JsonData from "./data/data.json";
import 'font-awesome/css/font-awesome.min.css';


function App() {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation data={landingPageData.Navigation}  />
      <Header data={landingPageData.Header}  />
      <AboutUs data={landingPageData.AboutUs}/>
      <Service data={landingPageData.Services}/>
      <Contact data={landingPageData.Contact}/>
      <Footer />
    </div>


  );
}



export default App;
