import React from "react";
import {Carousel} from "./carousel"

export const Header = (props) => {
  return (
    <section id="hero-area" className="header-area header-eight">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 col-md-12 col-12">
          <div className="header-content">
            <h1>{props.data ? props.data.title : "Loading"}</h1>
            <p>
            {props.data ? props.data.slogan : "Loading"}
            </p>
            <div className="button">
              <a href="#contact" className="btn primary-btn">{props.data ? props.data.buttonText : "Loading"}</a>
              {/*<a href="https://www.youtube.com/watch?v=r44RKWyfcFw&fbclid=IwAR21beSJORalzmzokxDRcGfkZA1AtRTE__l5N4r09HcGS5Y6vOluyouM9EM"
                className="glightbox video-button">
                <span className="btn icon-btn rounded-full">
                  <i className="lni lni-play"></i>
                </span>
                <span className="text">Watch Intro</span>
                <img src="assets/images/header/hero-image.jpg" alt="#" />


              </a>*/}
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 col-12">
          <div className="header-image">
            <Carousel  data= {props.data ? props.data.slides : []}/>

          </div>
        </div>
      </div>
    </div>
    </section>

    
  );
};
